@import 'src/scss/functions';
@import 'src/scss/palette';

.modal-mobile-wrapper {
    height: 100dvh !important;

    @media #{$min-width-mobile} {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;

        &.modal-ssr {
            z-index: 999;
        }
    }

    .modal-mobile {
        @media #{$min-width-mobile} {
            &.modal-dialog {
                transition: transform 0.2s ease-out !important;
            }
        }

        .modal-content {
            border-radius: 10px;
            background-color: $white;

            .modal-body {
                padding: 0;
            }
        }

        &__title {
            padding: snif-grid(2);
            text-align: center;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);

            p {
                position: relative;
            }
        }

        &__content {
            padding: snif-grid(3);
        }

        &__rectangle-mobile {
            display: none;
        }

        &__close-button {
            position: absolute;
            top: 2px;
            right: 0;
            cursor: pointer;
        }

        &__ssr {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 500;

            &.modal-mobile-ssr__hidden {
                display: none;
            }

            &.modal-mobile-ssr__open {
                display: block;
            }

            .modal-mobile {
                margin: snif-grid(8) auto 0;
                max-width: 500px;

                &__content {
                    max-height: calc(100vh - 184px);
                    overflow: auto;
                }
            }
        }

        &__ssr-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@media #{$max-width-mobile} {
    .modal-mobile-wrapper {
        overflow-y: hidden !important;

        &.fade .modal-mobile {
            transform: translate(0, 100px) !important;
            transition: transform 0.1s ease-out !important;
        }

        &.show .modal-mobile {
            transform: none !important;
        }

        .modal-mobile {
            position: absolute;
            bottom: 0;
            width: 100vw;
            max-width: 100vw;
            margin: 0;
            transition: none;

            .modal-content {
                border: none;
                border-radius: snif-grid(1) snif-grid(1) 0 0;

                .modal-body {
                    padding: 0;
                }
            }

            &__title {
                padding: snif-grid(2);
                text-align: center;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);

                p {
                    .text {
                        position: relative;
                        top: 4px;
                        max-width: calc(100% - 64px);
                        display: block;
                        margin: 0 auto;
                    }
                }
            }

            &__content {
                padding: snif-grid(3) snif-grid(2);
                max-height: calc(100vh - 176px);
                overflow-y: auto;
                overflow-y: overlay;
            }

            &__rectangle-mobile {
                display: block;
                width: 40px;
                height: 4px;
                position: absolute;
                left: 50%;
                top: -8px;
                transform: translateX(-50%);
                border-radius: 10px;
                background-color: $soft-gray;
            }

            &__close-button {
                left: 0;
            }
        }
    }
}

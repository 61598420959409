@import 'src/scss/palette';
@import 'src/scss/functions';

.nav-guest-mobile-unauth {
    position: fixed;
    top: 56px;
    left: 0;
    display: none;
    background-color: $white;
    border-top: 1px solid $fog-gray;
    width: 100vw;
    height: calc(100dvh - 56px);
    padding-top: snif-grid(2);
    overflow: auto;
    overflow: overlay;
    z-index: 999;

    a {
        color: $dark-gray;
        text-decoration: none;
    }

    &.active {
        display: block;
    }

    &.smart-b {
        height: calc(100dvh - 141px);
    }

    &.is-listings {
        top: $mobile-main-menu-height-listings;
        height: calc(100dvh - $mobile-main-menu-height-listings);

        &.is-top-bar-signup {
            top: 129px;
            height: calc(100dvh - 129px);
        }
    }

    &.is-spot-page {
        &.is-top-bar-signup {
            top: 113px;
            height: calc(100dvh - 113px);
        }
    }

    &__main-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: snif-grid(1) snif-grid(4);
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        border-bottom: 1px solid $fog-gray;

        a {
            display: block;
            width: 100%;
            margin-right: snif-grid(3);
            padding: snif-grid(3) 0;
        }

        .chev-menu {
            padding: snif-grid(3) 0 snif-grid(3) snif-grid(7);
            color: $light-gray;

            svg {
                width: 13px;
                height: 13px;
            }
        }
    }

    &__collapse-container {
        display: none;

        &.open {
            display: block;
        }
    }

    &__collapse {
        padding: snif-grid(4) snif-grid(4) snif-grid(7);
        background-color: $snow-white;
        border-bottom: 1px solid $fog-gray;
    }

    &__blog-post {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: snif-grid(3);

        img {
            width: 48px;
            height: 48px;
            margin-right: snif-grid(2);
            border-radius: 6px;
            object-fit: cover;
            object-position: center;
        }

        p {
            width: calc(100% - 64px);
        }
    }

    &__trainer-post {
        display: block;
        margin-top: snif-grid(3);

        &:nth-last-child(1) span {
            text-decoration: underline;
            text-underline-offset: 4px;
        }
    }
}

@import 'src/scss/palette';
@import 'src/scss/functions';

.nav-guest-unauth {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 snif-grid(4);
    background-color: $white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
    z-index: 50;

    @media #{$min-width-mobile} {
        min-width: 1024px;
    }

    &__left {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__logo {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: snif-grid(2.5);

        svg {
            height: 32px;
        }
    }

    &__main-menu {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            color: $deep-gray;
            transition: all 200ms;

            &:hover {
                color: $green;
                text-decoration: underline;
                text-underline-offset: 4px;
            }
        }

        > a {
            display: flex;
            align-items: center;
            height: 80px;
            padding: 0 snif-grid(2);
        }

        &.DP:hover {
            .parks {
                display: flex;
            }
        }

        &.BG:hover {
            .blog {
                display: block;
            }
        }

        &.TR:hover {
            .trainers {
                display: block;
            }
        }

        &.DR:hover {
            .dog-rescues {
                display: block;
            }
        }

        &.DN:hover {
            .dog-names {
                display: block;
            }
        }

        &.DB:hover {
            .dog-breeds {
                display: block;
            }
        }
    }

    &__right {
        display: flex;
        align-items: center;

        > a {
            &:nth-child(1) {
                margin-right: snif-grid(2);
            }
        }
    }

    &__popup {
        position: absolute;
        display: none;
        padding: snif-grid(4);
        left: 143px;
        top: 66px;
        background-color: $white;
        border-radius: 12px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
        z-index: 100;

        &:hover {
            display: block;

            &.parks {
                display: flex;
            }
        }

        a {
            color: $dark-gray;
            text-decoration: none;

            &:hover {
                color: $dark-gray;
                text-decoration: none;
            }
        }

        &.parks {
            > div:nth-child(1) {
                width: 256px;
                padding-right: snif-grid(7);
                border-right: 1px solid $fog-gray;
            }

            > div:nth-child(2) {
                width: 396px;
                padding-left: snif-grid(7);
            }
        }

        &.blog {
            width: 825px;
            left: 143px;

            .pop-blog-header {
                display: flex;
                flex-wrap: wrap;
                gap: 16px 0;
                margin-bottom: snif-grid(4);
            }
        }

        &.trainers {
            width: 360px;
            left: 629px;

            a:nth-last-child(1) span {
                text-decoration: underline;
                text-underline-offset: 4px;
            }
        }

        &.dog-names {
            width: 420px;
            left: 350px;
        }

        &.dog-rescues {
            width: 475px;
            left: 470px;
        }

        &.dog-breeds {
            width: 360px;
            left: 787px;
        }

        .flex-2-column {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: snif-grid(3);

            > a {
                width: 50%;
                margin-top: snif-grid(3);
            }

            &.blog-posts {
                margin: snif-grid(3) 0 0;
                gap: 24px 16px;

                a {
                    display: flex;
                    align-items: center;
                    width: calc(33% - 10.67px);
                    margin: 0;

                    img {
                        width: 48px;
                        height: 48px;
                        margin-right: snif-grid(1.5);
                        border-radius: 6px;
                        object-fit: cover;
                        object-position: center;
                    }

                    p {
                        width: calc(100% - 60px);
                        text-overflow: ellipsis;
                        display: block;
                        display: -webkit-box;
                        line-clamp: 2;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

@import 'src/scss/palette';
@import 'src/scss/variables';
@import 'src/scss/functions';

.pic-tag-img {
    background-color: $snow-white;
    background-image: url('../../assets/svg/SvgSpinner.svg');
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

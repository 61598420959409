@import '../../scss/palette';
@import '../../scss/font-mixin';
@import '../../scss/bootstrap/variables';
@import '../../scss/functions';

.navigation-layout.is-listing {
    @media screen and #{$max-width-mobile} {
        box-shadow: none !important;
    }
}

.navigation-guest {
    &__become-host-wrapper {
        margin-right: 30px;
    }

    &__become-host {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__right {
        display: flex;
        align-items: center;

        .host-button {
            margin-right: snif-grid(1);

            @media screen and #{$min-width-mobile} {
                margin-right: snif-grid(2);
            }
        }
    }

    &__search-bar-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 snif-grid(1.5) 0 snif-grid(2);
    }

    &__search-bar {
        display: flex;
        align-items: center;
        width: calc(100% - 52px);

        &_input {
            display: flex;
            align-items: center;
            width: calc(100% - 52px);
            height: 56px;
            padding: snif-grid(2);
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: $medium-gray;
            border: 1px solid $fog-gray;
            border-radius: 100px;

            svg {
                width: 20px;
                height: 20px;
                color: $black;
                opacity: 0.8;

                &:nth-last-child(1) {
                    position: relative;
                    top: 1px;
                    width: 24px;
                    height: 24px;
                }
            }

            &_text-container {
                width: calc(100% - 56px);
                color: $medium-gray;
                font-weight: 400;
                margin-left: snif-grid(1.5);

                > .snif-s1 {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                > .snif-s2 {
                    display: flex;
                    align-items: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                    span {
                        &:nth-child(1) {
                            margin-right: snif-grid(0.5);
                        }

                        &:nth-last-child(1) {
                            margin-left: snif-grid(0.5);
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }
            }
        }

        > svg {
            position: relative;
            top: 1px;
            width: 24px;
            height: 24px;
            margin: 0 snif-grid(2) 0 snif-grid(1.5);
        }
    }
}

@media screen and #{$max-width-mobile} {
    .navigation-layout {
        transition: transform 300ms ease-in-out;
        padding-right: 0;

        &.has-top-bar {
            box-shadow: none;
            border-bottom: 1px solid $fog-gray;
        }

        &.spot-test-present {
            box-shadow: none;
            border-bottom: 1px solid $fog-gray;
        }

        &.mob-show {
            transform: translateY(0);
        }

        &.mob-show-type {
            transform: translateY(0) !important;
        }

        &.mob-hide {
            transform: translateY(-56px);
        }
    }

    .chatbox-header {
        width: calc(100vw - 168px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        picture img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: snif-grid(1);
        }
    }

    .navigation-guest {
        &__left {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;

            .navigation-logo {
                padding-left: 14px;
            }

            .back-icon {
                position: relative;
                z-index: 1;
                padding: snif-grid(4) snif-grid(3) snif-grid(4) snif-grid(2);
                display: flex;
            }

            .nav-title {
                position: absolute;
                width: 100vw;
                padding: 0 56px;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.spot-detail {
                    text-align: left;
                    padding: 0 136px 0 48px;
                }
            }

            .listing-unauth-menu {
                padding: snif-grid(3) snif-grid(1.5) snif-grid(3) snif-grid(2);
            }

            .listing-nav {
                width: 100%;

                &.input-not-focused {
                    .location-search {
                        .cross {
                            display: none;
                        }
                    }
                }

                &.input-focused {
                    display: flex;
                    align-items: center;
                    padding: 0 0 0 16px;

                    > div:nth-child(1) {
                        width: 100%;
                    }

                    .sniff-button {
                        display: none;
                    }
                }

                &.filter-mode {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: snif-grid(2);
                    width: 100vw;
                    height: 100vh;
                    overflow-y: auto;
                    background: $white;
                    z-index: 150;

                    &.banner {
                        height: calc(100vh - 50px);
                    }

                    .filters-header {
                        display: flex;
                        justify-content: space-between;
                    }

                    .filter-view {
                        margin: snif-grid(3) 0 0 0;

                        .view-title {
                            @include snif-typography('m2');
                            margin-top: snif-grid(2);
                            font-weight: 600;
                        }

                        .tag {
                            @include snif-typography('s1');
                        }
                    }
                }

                .mobile-search-bar {
                    display: flex;

                    .location-search {
                        display: flex;
                        align-items: center;
                        box-shadow: none;

                        .sniff-input {
                            width: 100%;
                            min-height: 40px;
                            height: 40px;
                            font-size: 14px;
                            border-radius: 0;
                            padding-right: 16px;
                            border: 1px solid transparent;
                            border-left: 1px solid $fog-gray;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            transition: all 200ms;

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        &.home-page-search__focus .sniff-input {
                            border: 1px solid $fog-gray;
                            border-radius: 54px;
                            padding-right: 72px;
                            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
                        }

                        .search-ico {
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        .cross {
                            background-size: 24px;
                            padding: 12px;
                            top: 8px;
                            right: 92px;
                        }

                        .result {
                            top: 80px;
                            box-shadow: none;
                        }
                    }

                    .sniff-button_size_sm {
                        border: none;
                        padding: 0 8px 0 16px;
                        position: relative;

                        .green-dot {
                            position: absolute;
                            background-color: $green;
                            color: $white;
                            border-radius: 50%;
                            width: 16px;
                            height: 16px;
                            font-size: 10px;
                            line-height: 16px;
                            top: 0;
                            right: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        &:nth-last-child(1) {
                            padding: 0 16px 0 8px;
                        }

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                &.home-test {
                    .mobile-search-bar .location-search {
                        margin-left: snif-grid(0.5);

                        .sniff-input {
                            border-left: none;
                        }
                    }
                }

                .options-button {
                    display: none;
                }
            }
        }

        &__right {
            display: flex;

            .nav-action {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                z-index: 10;

                .spot-fav-fab__fab {
                    position: relative;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin-right: snif-grid(2);
                }

                .spot-share-button__box {
                    margin-right: snif-grid(2);
                    z-index: 10;
                }

                .header-logo-wrapper {
                    padding-left: 0;
                }

                .res-filter {
                    position: relative;
                    padding-right: snif-grid(2);
                    z-index: 10;

                    .green-dot {
                        position: absolute;
                        background-color: $green;
                        color: $white;
                        border-radius: 50%;
                        width: 16px;
                        height: 16px;
                        font-size: 10px;
                        line-height: 16px;
                        top: -7px;
                        right: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .spot-cal-picker {
                    display: flex;
                    align-items: center;
                    padding: snif-grid(2);

                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 4px;
                    }

                    svg {
                        margin-left: 8px;
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    .res-filter-modal {
        .modal-content {
            height: 100vh !important;
        }
    }
}

.modal-select-spot {
    display: flex;
    align-items: center;
    margin-top: snif-grid(3);

    img {
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
    }
}

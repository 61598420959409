@import 'src/scss/palette';
@import 'src/scss/functions';

.sniff-smart-banner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 14px;
    background-color: $white;
    border-bottom: 1px solid $snow-white;
    z-index: 115;

    &__left {
        display: flex;
        align-items: center;

        &_close {
            display: flex;
            align-items: center;

            div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-self: stretch;
                padding-right: snif-grid(1);
            }

            svg {
                width: 12px;
                height: 12px;
                color: $medium-gray;
            }
        }

        &_content {
            margin-left: snif-grid(1);

            > p {
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: $black;
                letter-spacing: 0.5px;

                &:nth-child(2) {
                    font-size: 12px;
                    font-weight: 400;
                    color: $medium-gray;
                    letter-spacing: 0.45px;
                }
            }

            > div {
                &:nth-child(3) {
                    display: flex;
                    align-items: center;
                    height: 16px;
                    font-size: 9px;
                    font-weight: 400;
                    color: $medium-gray;
                    letter-spacing: 0.35px;

                    > .raiting {
                        margin-right: snif-grid(0.5);

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }
    }

    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 18px;
            color: #fff;
            text-align: right;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.52px;
            border-radius: 100px;
            background: #007aff;
        }
    }

    @media #{$min-width-mobile} {
        display: none !important;
    }
}

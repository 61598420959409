@import 'src/scss/variables';

.raiting {
    display: flex;
    align-items: center;
    .stars {
        display: flex;
    }
    .raiting-value {
        margin-left: 1rem;
    }
}

@media screen and (max-width: $mobile-size) {
    .raiting {
        .stars {
            .half,
            .active,
            .inactive {
                width: 1.4rem;
                height: 1.4rem;
            }
        }
        .raiting-value {
            margin-left: 0.5rem;
        }
    }
}

@import 'src/scss/palette';
@import 'src/scss/variables';

.header-logo-wrapper {
    margin-right: 0;
    padding: 0 16px 0 8px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    @media #{$min-width-mobile} {
        padding-right: 0;
    }

    &__user-logo {
        display: flex;
        align-items: center;

        @media #{$max-width-mobile} {
            width: 32px !important;
            height: 32px !important;
        }
    }

    &__toggler {
        position: relative;
    }

    &__menu-icon {
        color: $light-gray;
        margin-left: 8px;
        width: 15px;
        height: 15px;
        display: inline-block;

        @media #{$max-width-mobile} {
            margin-left: 6px;
            width: 8px;
            height: 8px;
        }
    }

    &__notification-icon {
        position: absolute;
        color: $success-500;
        font-size: 10px;
        top: -7px;
        right: -4px;
    }

    &.mobile-user-menu {
        padding: 0 16px 0 0;
    }
}

@import 'src/scss/palette';
@import 'src/scss/variables';

$in-shadow: inset 0px 2px 4px rgba($black, 0.25);

@mixin button-block {
    padding-right: 5px;
    padding-left: 5px;
    width: 100%;
}

.sniff-button {
    padding: 11px 23px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid transparent;
    letter-spacing: 0.2px;
    border-radius: 6px;
    transition: 200ms;
    text-decoration: none;
    text-align: center;

    $root: &;
    &:focus,
    &:hover {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed;
    }

    &_block {
        @include button-block;

        &_mobile {
            @media #{$max-width-mobile} {
                @include button-block;
            }
        }

        &_desktop {
            @media #{$min-width-mobile} {
                @include button-block;
            }
        }
    }

    &_size {
        &_sm {
            padding: 3px 11px;
            font-size: 14px;
            border-radius: 4px;
        }
        &_md {
            padding: 7px 15px;
        }
        &_lg {
            padding: 11px 23px;
        }
    }

    &_color {
        &_primary {
            color: $white;
            background-color: map-get($map-green, '500');

            @media #{$min-width-mobile} {
                &:hover {
                    background-color: map-get($map-green, '400');
                }
            }

            &:active {
                background-color: map-get($map-green, '600');
            }

            &:disabled {
                color: $white;
                background-color: $light-gray;
            }
        }

        &_secondary {
            font-weight: 500;
            color: $deep-gray;
            background-color: $white;
            border-color: $light-gray;

            @media #{$min-width-mobile} {
                &:hover {
                    color: map-get($map-green, '400');
                    border-color: map-get($map-green, '400');
                }
            }

            &:active {
                color: $deep-gray;
                background-color: $snow-white;
                border-color: $light-gray;
            }

            &:disabled {
                color: $medium-gray;
                background-color: $fog-gray;
                border-color: $fog-gray;
            }
        }

        &_secondary-dashed {
            font-weight: 500;
            color: #000;
            background-color: $white;
            border: 1px dashed $light-gray;

            @media #{$min-width-mobile} {
                &:hover {
                    color: $deep-gray;
                }
            }

            &:active {
                color: $deep-gray;
            }

            &:disabled {
                color: $medium-gray;
                background-color: $fog-gray;
                border-color: $fog-gray;
            }
        }

        &_danger {
            color: $white;
            background-color: $red-500;

            @media #{$min-width-mobile} {
                &:hover {
                    background-color: $red-400;
                }
            }

            &:active {
                background-color: $red-600;
            }

            &:disabled {
                color: $white;
                background-color: $light-gray;
            }
        }

        &_transparent {
            font-weight: 500;
            color: $deep-gray;
            text-decoration: underline;
            text-underline-offset: 4px;
            background-color: transparent;
            border-color: transparent;
        }
    }
}

@import 'src/scss/functions';

.navigation-logo {
    &__img {
        height: 34px;
    }
}

@media screen and (max-width: $mobile-size) {
    .navigation-logo {
        &__img {
            position: relative;
            padding-left: 16px;
            z-index: 25;
        }

        &__back-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 21px 16px;
            z-index: 25;

            svg {
                width: 12px;
                height: 14px;
            }
        }
    }
}

.spot-fav-fab {
    &__fab {
        cursor: pointer;
        position: absolute;
        display: flex;
        top: 12px;
        right: 12px;
        z-index: 9;
    }
}

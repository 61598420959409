@import 'src/scss/palette';
@import 'src/scss/functions';

.top-bar-signup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: snif-grid(1.5) snif-grid(2);
    border-bottom: 1px solid $fog-gray;

    @media #{$min-width-mobile} {
        display: none;
    }
}

@import 'src/scss/palette';
@import 'src/scss/functions';

.spot-share-button {
    &__box {
        .share-button {
            cursor: pointer;
        }
    }
}

.share-modal {
    .share-box {
        .react-share__ShareButton {
            display: inline-block;
            margin: 0 snif-grid(1.5) snif-grid(1.5) 0;
            cursor: pointer;
        }
    }

    .share-box-copy {
        input {
            margin-bottom: snif-grid(2);
        }

        @media screen and #{$min-width-mobile} {
            display: flex;
            align-items: center;

            input {
                max-width: 324px;
                margin-bottom: 0;
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &__action {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    @media screen and #{$min-width-mobile} {
        max-width: 650px;
    }
}

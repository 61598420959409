@import '../variables';

@mixin font-weight($weight: 'regular') {
    font-weight: map-get($snif-font-weights, $weight);
}

@function get-snif-typography($variant: 'p') {
    @return map-get($snif-typographies, $variant);
}

@mixin snif-typography($variant: 'p', $weight: null) {
    $typography: map-get($snif-typographies, $variant);
    @if $typography {
        font-size: map-get($typography, 'size') !important;
        line-height: map-get($typography, 'height') !important;
    }
}
